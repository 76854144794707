#dateselector {
  margin-top: 10px; }
  #dateselector .current {
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    padding: 10px 0;
    border-top: 1px solid #44453E; }
    #dateselector .current .toggle {
      float: right;
      color: #A1A29E; }
      #dateselector .current .toggle:hover {
        color: #FFF; }
  #dateselector .buttons button {
    border: 0;
    background: none;
    color: #C7C7C5;
    font-size: 18px;
    display: inline-block;
    width: 16.66667%;
    padding: 10px 0;
    border-right: 1px solid #44453E;
    cursor: pointer; }
    #dateselector .buttons button:hover {
      background: #44453E;
      color: #FFF; }
    #dateselector .buttons button:last-child {
      border-right: 0; }
    #dateselector .buttons button:focus {
      outline: none; }
  #dateselector .picker {
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    width: 250px;
    margin: 10px auto 0; }
    #dateselector .picker th,
    #dateselector .picker td {
      width: 14.28571%; }
    #dateselector .picker th {
      background: #272822;
      font-weight: normal;
      text-align: center;
      padding: 3px; }
      #dateselector .picker th:first-child {
        border-radius: 4px 0 0 4px; }
      #dateselector .picker th:last-child {
        border-radius: 0 4px 4px 0; }
    #dateselector .picker a {
      display: block;
      text-decoration: none;
      padding: 4px;
      color: #D4FFBC;
      border-radius: 4px;
      transition: none; }
      #dateselector .picker a:hover {
        background: #D4FFBC;
        color: #000;
        text-decoration: none; }
      #dateselector .picker a.selected {
        background: #D4FFBC;
        color: #000; }
