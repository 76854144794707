#welcome h1 {
  font-family: "Roboto Slab", sans-serif;
  margin: 0 0 15px;
  font-size: 30px;
  font-weight: 400; }

#welcome .intro-top {
  font-family: "Roboto Slab", sans-serif;
  font-size: 20px; }

#welcome .intro-sub {
  color: #C7C7C5;
  line-height: 20px; }

#welcome .credit {
  margin-top: 20px;
  text-align: center;
  color: #A1A29E; }
  #welcome .credit a {
    color: #C7C7C5; }
    #welcome .credit a:hover {
      color: #A1A29E; }

#welcome .search {
  margin-top: 25px;
  text-align: center; }
  #welcome .search .countries {
    width: 132px;
    display: inline-block;
    padding-left: 2px; }
    #welcome .search .countries a {
      display: inline-block;
      margin-right: 2px; }
  #welcome .search .cities {
    display: inline-block;
    background: #73A857;
    border-radius: 4px;
    text-transform: uppercase;
    width: 120px;
    padding: 9px 0;
    color: #FFF;
    vertical-align: top; }
  #welcome .search .geolocate a {
    background: #5772A8;
    border-radius: 4px;
    text-transform: uppercase;
    text-align: center;
    width: 258px;
    padding: 9px 0;
    color: #FFF;
    display: inline-block;
    margin-bottom: 3px; }
    #welcome .search .geolocate a.error {
      background: #A12F2F; }
