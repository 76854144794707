#timezoneselector {
  background: linear-gradient(#373830, #272822) no-repeat fixed;
  z-index: 100000;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 20px 20px;
  overflow-y: scroll; }
  #timezoneselector h1 {
    font-family: "Roboto Slab", sans-serif;
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-size: 25px; }
    #timezoneselector h1 a {
      color: #6D6E68;
      float: right;
      clear: none; }
      #timezoneselector h1 a:hover {
        color: #FFD700; }
  #timezoneselector h2 {
    font-family: "Roboto Slab", sans-serif;
    font-weight: normal;
    color: #FFF;
    margin: 10px 0;
    padding: 0;
    font-size: 20px; }
  @media (max-width: 800px) {
    #timezoneselector {
      position: fixed;
      transform: translateY(100%);
      max-height: 100%; } }
  @media (min-width: 801px) {
    #timezoneselector {
      position: absolute;
      transform: translateX(100%);
      max-height: 100%; } }

#timezoneselector .zone-list a {
  display: block;
  text-decoration: none;
  padding: 3px;
  border-radius: 4px; }
  #timezoneselector .zone-list a:hover {
    background: #494A42; }

#timezoneselector .zone-list a {
  color: #D4FFBC; }
  #timezoneselector .zone-list a span {
    color: #FFF;
    padding-right: 4px; }
