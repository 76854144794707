html {
  background: linear-gradient(#373830, #272822) no-repeat fixed;
  background-size: cover;
  min-height: 100%; }

body {
  margin: 0;
  background: linear-gradient(#373830, #272822) no-repeat fixed;
  background-size: cover;
  min-height: 100%;
  font-family: "Lato", sans-serif;
  font-size: 14px;
  color: #FFF;
  padding: 0; }

a {
  cursor: pointer;
  color: #D4FFBC;
  text-decoration: none;
  transition: background, color .25s; }
  a:hover, a:active {
    color: #FFF; }

.clear {
  display: block;
  margin: 0;
  clear: both;
  width: 100%;
  font-size: 0;
  height: 1px;
  padding: 0; }

input,
select {
  font-size: 100%; }

.slide-in {
  animation: slide-in .3s forwards;
  animation-timing-function: ease-out; }

.slide-out {
  animation: slide-out .3s forwards;
  animation-timing-function: ease-out; }

@media (max-width: 800px) {
  @keyframes slide-in {
    0% {
      display: block; }
    100% {
      transform: translateY(0%); } }
  @keyframes slide-out {
    0% {
      transform: translateY(0%); }
    100% {
      display: none;
      transform: translateY(100%); } } }

@media (min-width: 801px) {
  @keyframes slide-in {
    0% {
      display: block; }
    100% {
      transform: translateX(0%); } }
  @keyframes slide-out {
    0% {
      transform: translateX(0%); }
    100% {
      display: none;
      transform: translateX(100%); } } }
