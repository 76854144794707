#cityselector {
  background: linear-gradient(#373830, #272822) no-repeat fixed;
  z-index: 100000;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 20px 20px;
  overflow-y: scroll; }
  #cityselector h1 {
    font-family: "Roboto Slab", sans-serif;
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-size: 25px; }
    #cityselector h1 a {
      color: #6D6E68;
      float: right;
      clear: none; }
      #cityselector h1 a:hover {
        color: #FFD700; }
  #cityselector h2 {
    font-family: "Roboto Slab", sans-serif;
    font-weight: normal;
    color: #FFF;
    margin: 10px 0;
    padding: 0;
    font-size: 20px; }
  @media (max-width: 800px) {
    #cityselector {
      position: fixed;
      transform: translateY(100%);
      max-height: 100%; } }
  @media (min-width: 801px) {
    #cityselector {
      position: absolute;
      transform: translateX(100%);
      max-height: 100%; } }

#cityselector ol li .country, #cityselector ol li ol a {
  display: block;
  text-decoration: none;
  padding: 3px;
  border-radius: 4px; }
  #cityselector ol li .country:hover, #cityselector ol li ol a:hover {
    background: #494A42; }

#cityselector ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  #cityselector ol li .country {
    color: #DADAD9; }
    #cityselector ol li .country:hover {
      color: #FFF; }
    #cityselector ol li .country .flag {
      display: inline-block;
      margin-right: 7px;
      position: relative;
      top: 2px; }
  #cityselector ol li.active > .country {
    color: #FFF; }
  #cityselector ol li ol {
    display: none; }
    #cityselector ol li ol a {
      margin-left: 30px; }
  #cityselector ol li.active > ol {
    display: block; }
