#loading {
  padding-top: 20px;
  font-size: 16px;
  color: #A1A29E;
  text-align: center; }
  #loading .fa {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 25px; }

#data h1 {
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
  font-size: 30px;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; }

#data h2 {
  font-family: "Roboto Slab", sans-serif;
  font-weight: 400;
  font-size: 20px;
  margin: 0;
  color: #D4FFBC; }

#data #tzwarning {
  font-size: 16px;
  color: #FFD700;
  border: 2px solid #FFD700;
  margin: 10px 0;
  padding: 6px 10px 8px;
  border-radius: 6px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer; }
  #data #tzwarning:hover {
    color: #FFF;
    border-color: #FFF; }

#data #sun,
#data #moon,
#data #zone {
  margin-top: 7px;
  padding-top: 7px;
  border-top: 1px solid #44453E; }

#data #sun-image,
#data #moon-image {
  height: 100px;
  width: 100px;
  float: left;
  margin-right: 15px;
  margin-top: 2px;
  margin-bottom: 15px; }
  @media (max-width: 360px) {
    #data #sun-image,
    #data #moon-image {
      width: 80px;
      height: 80px; } }

#data .row-special {
  padding-top: 23px;
  font-size: 150%; }

#data .row {
  font-size: 18px;
  margin-top: 5px; }
  #data .row .label {
    color: #D4FFBC;
    display: block;
    width: 65px;
    padding-top: 7px;
    float: left; }
  #data .row .value {
    font-size: 25px;
    color: #FFF; }
  #data .row .zone {
    color: #A1A29E; }

#data #phase .label {
  padding-top: 6px; }

#data #phase .value {
  font-size: 18px;
  padding-top: 6px;
  display: inline-block; }

#data #zone {
  padding-top: 15px; }
  #data #zone #zone-header {
    color: #FFF;
    display: block;
    font-size: 16px; }
  #data #zone #zone-name {
    color: #D5D5D3;
    display: block;
    font-size: 100%;
    margin-bottom: 5px; }
  #data #zone #zone-link {
    font-size: 22px;
    float: right;
    color: #A1A29E; }
    #data #zone #zone-link:hover {
      color: #FFF; }

#data.loading .value,
#data.loading .zone,
#data.loading .row-special,
#data.loading #zone {
  opacity: .3; }

#data .pdf {
  display: block;
  float: left;
  margin: 10px 0; }
