#panel {
  position: absolute;
  right: 0;
  left: 75%;
  top: 0;
  padding: 15px 20px 20px; }
  @media (max-width: 1400px) {
    #panel {
      left: 70%; } }
  @media (max-width: 1150px) {
    #panel {
      left: 65%; } }
  @media (max-width: 1000px) {
    #panel {
      left: 60%; } }
  @media (max-width: 900px) {
    #panel {
      left: 55%; } }
  @media (max-width: 800px) {
    #panel {
      position: static;
      padding-top: 10px; } }
  @media (min-width: 801px) {
    #panel {
      max-height: 100%;
      min-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      box-sizing: border-box; } }
  @media (max-width: 360px) {
    #panel {
      padding: 15px; } }
