#map-container {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 25%;
  bottom: 15px; }
  @media (max-width: 1400px) {
    #map-container {
      right: 30%; } }
  @media (max-width: 1150px) {
    #map-container {
      right: 35%; } }
  @media (max-width: 1000px) {
    #map-container {
      right: 40%; } }
  @media (max-width: 900px) {
    #map-container {
      right: 45%; } }
  @media (max-width: 800px) {
    #map-container {
      position: static;
      height: 45vh; } }

#map {
  width: 100%;
  height: 100%; }

#map-controls {
  position: absolute;
  left: 0;
  margin-top: 10px;
  margin-left: 10px;
  z-index: 50000; }
  #map-controls #zoom-in,
  #map-controls #zoom-out {
    background: rgba(50, 51, 43, 0.75);
    transition: background .25s;
    color: #FFF;
    position: absolute;
    width: 24px;
    height: 24px;
    cursor: pointer;
    text-align: center; }
    #map-controls #zoom-in .fa,
    #map-controls #zoom-out .fa {
      position: relative;
      top: 3px; }
    #map-controls #zoom-in:hover,
    #map-controls #zoom-out:hover {
      background: #32332b; }
  #map-controls #zoom-out {
    top: 0;
    left: 0;
    border-radius: 4px 0 0 4px; }
  #map-controls #zoom-in {
    top: 0;
    left: 24px;
    border-radius: 0 4px 4px 0; }
  #map-controls #type-select {
    position: absolute;
    top: 0;
    left: 50px;
    width: 100px;
    background: rgba(50, 51, 43, 0.75);
    color: #FFF;
    padding: 3px 5px 4px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background .25s; }
    #map-controls #type-select .fa {
      float: right;
      position: relative;
      top: 2px; }
    #map-controls #type-select:hover {
      background: #32332b; }
  #map-controls #type-selection {
    position: absolute;
    top: 26px;
    left: 50px;
    width: 110px;
    background: rgba(50, 51, 43, 0.75);
    border-radius: 4px;
    z-index: 5000;
    max-height: 0;
    overflow: hidden;
    transition: max-height .8s; }
    #map-controls #type-selection.active {
      max-height: 1000px; }
    #map-controls #type-selection ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
      #map-controls #type-selection ul li {
        margin: 0;
        padding: 0; }
        #map-controls #type-selection ul li a {
          display: block;
          padding: 5px;
          color: #FFF;
          cursor: pointer;
          transition: background .25s; }
          #map-controls #type-selection ul li a:hover {
            background: #32332B; }
